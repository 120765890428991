function initMap() {

  var myLatLng = {lat: 51.224571, lng: 58.331552};
  var myCenter = {lat: 51.224584, lng: 58.330039};

  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: 16,
    center: myCenter,


  });

  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: 'Arbolit, Оренбургская область, г. Новотроицк, промышленная, 11'
  });

  var map2 = new google.maps.Map(document.getElementById('mapXS'), {
    zoom: 16,
    center: myLatLng,


  });

  var marker2 = new google.maps.Marker({
    position: myLatLng,
    map: map2,
    title: 'Arbolit, Оренбургская область, г. Новотроицк, промышленная, 11'
  });


}

jQuery(function($){
$(".phone_input").mask("+7 (999) 999-99-99");
});

$(document).ready(function() {
  var carousel = $("#project_carousel").featureCarousel({
    autoPlay: 4000,
    smallFeatureWidth: 0.63,
    smallFeatureHeight: 0.63,
    trackerSummation: false,
    trackerIndividual: false
  });
});

$('a[href^="#nav"]').click(function() {
  if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    if (target.length) {
      var scrollLength = target.offset().top - 55;
      $('html, body').animate({
        scrollTop: scrollLength
      }, 1000);
      return false;
    }
  }
});

$(function() {
    $('.navbar-collapse a').on('click', function(){ 
        if($('.navbar-toggle').css('display') !='none'){
            $(".navbar-toggle").trigger( "click" );
        }
    });
});

$('body').on('click', function(event){
  if (!$(event.target).closest('.navbar-collapse.collapse.in').length){
    $('.navbar-collapse').collapse('hide');
  }
});

document.getElementById('callbackFirst').addEventListener('submit', function(evt){
var http = new XMLHttpRequest(), f = this;
evt.preventDefault();
http.open("POST", "sendmail.php", true);
http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
http.send("name=" + f.name.value + "&phone=" + f.phone.value);
http.onreadystatechange = function() {
  if (http.readyState == 4 && http.status == 200) {
    f.name.removeAttribute('value');
    f.name.value='';
    f.phone.removeAttribute('value');
    f.phone.value='';
    $('#callback').modal('hide');
    setTimeout($('#thx').modal('show'), 500);
  }
}
http.onerror = function() {
  alert('Извините, данные не были переданы');
}
}, false);

document.getElementById('dostavkaForm').addEventListener('submit', function(evt){
var http = new XMLHttpRequest(), f = this;
evt.preventDefault();
http.open("POST", "sendmail.php", true);
http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
http.send("name=" + f.name.value + "&phone=" + f.phone.value);
http.onreadystatechange = function() {
  if (http.readyState == 4 && http.status == 200) {
    f.name.removeAttribute('value');
    f.name.value='';
    f.phone.removeAttribute('value');
    f.phone.value='';
    $('#callback').modal('hide');
    setTimeout($('#thx').modal('show'), 500);
  }
}
http.onerror = function() {
  alert('Извините, данные не были переданы');
}
}, false);

document.getElementById('stepForm').addEventListener('submit', function(evt){
var http = new XMLHttpRequest(), f = this;
evt.preventDefault();
http.open("POST", "sendmail.php", true);
http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
http.send("name=" + f.name.value + "&phone=" + f.phone.value);
http.onreadystatechange = function() {
  if (http.readyState == 4 && http.status == 200) {
    f.name.removeAttribute('value');
    f.name.value='';
    f.phone.removeAttribute('value');
    f.phone.value='';
    $('#callback').modal('hide');
    setTimeout($('#thx').modal('show'), 500);
  }
}
http.onerror = function() {
  alert('Извините, данные не были переданы');
}
}, false);

document.getElementById('presentForm').addEventListener('submit', function(evt){
var http = new XMLHttpRequest(), f = this;
evt.preventDefault();
http.open("POST", "sendmail.php", true);
http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
http.send("name=" + f.name.value + "&phone=" + f.phone.value);
http.onreadystatechange = function() {
  if (http.readyState == 4 && http.status == 200) {
    f.name.removeAttribute('value');
    f.name.value='';
    f.phone.removeAttribute('value');
    f.phone.value='';
    setTimeout($('#thx').modal('show'), 500);
  }
}
http.onerror = function() {
  alert('Извините, данные не были переданы');
}
}, false);

document.getElementById('mainScreen').addEventListener('submit', function(evt){
var http = new XMLHttpRequest(), f = this;
evt.preventDefault();
http.open("POST", "sendmail.php", true);
http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
http.send("name=" + f.name.value + "&phone=" + f.phone.value + "&email=" + f.email.value);
http.onreadystatechange = function() {
  if (http.readyState == 4 && http.status == 200) {
    f.name.removeAttribute('value');
    f.name.value='';
    f.phone.removeAttribute('value');
    f.phone.value='';
    f.email.removeAttribute('value');
    f.email.value='';
    setTimeout($('#thx').modal('show'), 500);
  }
}
http.onerror = function() {
  alert('Извините, данные не были переданы');
}
}, false);